import React, { useContext, useState } from "react";
import {
  Modal,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import GlobalStateContext from "../context/globalStateContext";
import { COLORS } from "../utils/COLORS";
import UserContext from "../context/userContext";
import { firebase, functions } from "../firebaseConfig";
import { httpsCallable } from "firebase/functions";

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
  width: "90%",
  maxWidth: "400px",
  maxHeight: "80vh",
  overflowY: "auto",
  textAlign: "center",
}));

const AddCardModal = () => {
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setGlobalState({ ...globalState, showAddCardModal: false });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
    setErrorMessage("");

    if (!stripe || !elements) {
      setIsProcessing(false);
      return;
    }

    try {
      // Create a PaymentMethod
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (error) {
        throw new Error(error.message);
      }

      // Use httpsCallable to call the Cloud Function
      const createStripeUser = httpsCallable(functions, "createStripeUser");
      const result = await createStripeUser({
        paymentMethodId: paymentMethod.id,
        email: user.email, // Assuming user.email is available
      });

      // const { id: stripeCustomerId, paymentMethodId } = result.data;
      const { customerId, clientSecret } = result.data;

      // Save the stripeCustomerId to Firebase Realtime Database
      await firebase.database().ref(`users/${user.uid}`).update({
        stripeCustomerId: customerId,
        clientSecret,
        stripePaymentMethodId: paymentMethod.id,
      });

      // Close this modal and reopen the pledge modal
      setGlobalState({ ...globalState, showAddCardModal: false });

      setTimeout(() => {
        setGlobalState({
          ...globalState,
          showPledgeModal: true,
          showAddCardModal: false,
        });
      }, 1000);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <StyledModal
      open={globalState.showAddCardModal}
      onClose={handleClose}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
      }}
    >
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        exit={{ y: "100%" }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <ModalContent>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: COLORS.primaryGreen, mb: 5 }}
          >
            Add Your Card
          </Typography>
          <form onSubmit={handleSubmit}>
            <CardElement options={{ style: { base: { fontSize: "16px" } } }} />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={isProcessing}
              sx={{
                mt: 5,
                backgroundColor: COLORS.primaryGreen,
                "&:hover": {
                  backgroundColor: COLORS.darkGreen,
                },
              }}
            >
              {isProcessing ? <CircularProgress size={24} /> : "Save Card"}
            </Button>
          </form>
        </ModalContent>
      </motion.div>
    </StyledModal>
  );
};

export default AddCardModal;
