import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import Gradient from "../assets/gradient2.png"
import LogoImg from "../assets/trans_logo.png"
import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/Email'; 
import { COLORS } from '../utils/COLORS';

const Background = styled(Box)({
  backgroundColor: COLORS.yellow,
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  position: 'relative', // Added to position the terms at the bottom
});

const Logo = styled('img')({
  width: '150px',
  marginBottom: '20px',
});

const Subtitle = styled(Typography)({
  marginBottom: '20px',
  color: '#fff', // Added color for better UI/UX
});

const Terms = styled(Typography)({
  bottom: '14px',
  fontSize: '12px',
  cursor:'pointer',
  color: '#000', // Added color for better UI/UX
});

const ContactPage = () => {
    return (
      <Background>
        <Logo src={LogoImg} alt="Logo" />
        <Button
          variant="contained"
          color="primary"
          style={{
            backgroundColor: '#007aff',
            borderRadius: '20px',
            padding: '12px 24px',
            fontSize: '16px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          startIcon={<EmailIcon />}
          onClick={() => window.location.href='mailto:hi@paws.lol'}
        >
          Email Us
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '20vw', position: 'absolute', bottom: '14px' }}>
          <Terms onClick={() => window.location.href='/'} variant="body2">Home</Terms>
          <Terms onClick={() => window.location.href='/privacy'} variant="body2">Privacy Policy</Terms>
        </Box>
      </Background>
    );
  }

export default ContactPage;