import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/database";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCefVh37hzb0Tuq06sMDrqsYln83uC9bMs",
  authDomain: "pawsai-ecd07.firebaseapp.com",
  databaseURL: "https://pawsai-ecd07-default-rtdb.firebaseio.com",
  projectId: "pawsai-ecd07",
  storageBucket: "pawsai-ecd07.appspot.com",
  messagingSenderId: "61599301414",
  appId: "1:61599301414:web:16b70719883ed7d8cf4be7",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  console.log("Firebase initialized");
}

const firestore = firebase.firestore();

const functions = firebase.functions();

const database = firebase.database();

const storage = firebase.storage();

const auth = firebase.auth();

const googleProvider = new firebase.auth.GoogleAuthProvider();

export {
  firebase,
  firestore,
  functions,
  database,
  storage,
  auth,
  googleProvider,
};
