import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";
import Gradient from "../assets/gradient2.png";
import LogoImg from "../assets/trans_logo.png";
import AppleIcon from "@mui/icons-material/Apple";
import {useTheme} from "@mui/material";
import { COLORS } from "../utils/COLORS";

const Background = styled(Box)(({ theme }) => ({
  backgroundColor: COLORS.yellow,
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: theme.spacing(4),
  position: "relative",
}));

const Logo = styled("img")({
  width: "150px",
  marginBottom: "20px",
});

const Content = styled(Box)(({ theme }) => ({
  maxWidth: "800px",
  padding: theme.spacing(4),
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: "#000",
  fontWeight: "bold",
}));

const Terms = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  cursor: "pointer",
  color: "#007bff",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const PrivacyPage = () => {
  return (
    <Background>
      <Content>
        <Logo src={LogoImg} alt="Logo" style={{cursor:'pointer'}} onClick={() => window.location.href='/'} />
        <Subtitle variant="h4">Privacy Policy</Subtitle>
        <Typography variant="body1" sx={{ marginBottom: "20px" }}>
          At PawsAI, we are committed to protecting the privacy and security of
          our users' personal information. This privacy policy outlines how we
          collect, use, and protect the information you provide to us.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "20px" }}>
          <strong>Collection of Information</strong>
          <br />
          We collect information from you when you register on our site, place an
          order, subscribe to our newsletter, respond to a survey or fill out a
          form. When ordering or registering on our site, as appropriate, you may
          be asked to enter your name, email address, mailing address, phone
          number or credit card information. You may, however, visit our site
          anonymously.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "20px" }}>
          <strong>Use of Information</strong>
          <br />
          Any of the information we collect from you may be used in one of the
          following ways:
          <ul>
            <li>
              To personalize your experience (your information helps us to better
              respond to your individual needs)
            </li>
            <li>
              To improve our website (we continually strive to improve our website
              offerings based on the information and feedback we receive from you)
            </li>
            <li>
              To improve customer service (your information helps us to more
              effectively respond to your customer service requests and support
              needs)
            </li>
            <li>
              To process transactions (your information, whether public or
              private, will not be sold, exchanged, transferred, or given to any
              other company for any reason whatsoever, without your consent, other
              than for the express purpose of delivering the purchased product or
              service requested)
            </li>
            <li>
              To administer a contest, promotion, survey or other site feature
            </li>
            <li>
              To send periodic emails (the email address you provide for order
              processing, may be used to send you information and updates
              pertaining to your order, in addition to receiving occasional
              company news, updates, related product or service information, etc.)
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "20px" }}>
          <strong>Protection of Information</strong>
          <br />
          We implement a variety of security measures to maintain the safety of
          your personal information when you place an order or enter, submit, or
          access your personal information. We offer the use of a secure server.
          All supplied sensitive/credit information is transmitted via Secure
          Socket Layer (SSL) technology and then encrypted into our Payment
          gateway providers database only to be accessible by those authorized
          with special access rights to such systems, and are required to keep the
          information confidential.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "20px" }}>
          <strong>Sharing of Information</strong>
          <br />
          We do not sell, trade, or otherwise transfer to outside parties your
          personally identifiable information. This does not include trusted third
          parties who assist us in operating our website, conducting our business,
          or servicing you, so long as those parties agree to keep this
          information confidential. We may also release your information when we
          believe release is appropriate to comply with the law, enforce our site
          policies, or protect ours or others rights, property, or safety.
          However, non-personally identifiable visitor information may be provided
          to other parties for marketing, advertising, or other uses.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "20px" }}>
          <strong>Analytics</strong>
          <br />
          We use analytics tools to collect information about how users interact
          with our app. This information is used to improve the user experience
          and is not linked to any personal identifiable information.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "20px" }}>
          <strong>Disclaimer</strong>
          <br />
          The Macro Nutrition Analysis app is not intended to provide medical
          advice or to be used as a substitute for professional veterinary advice.
          The information provided is for general informational purposes only and
          should not be relied upon as a substitute for professional advice. You
          should consult a veterinarian before making any decisions about your
          pet's health.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "20px" }}>
          <strong>Changes to this Policy</strong>
          <br />
          If we decide to change our privacy policy, we will post those changes on
          this page, and/or update the Privacy Policy modification date below.
          Policy changes will apply only to information collected after the date
          of the change.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "20px" }}>
          <strong>Contact Us</strong>
          <br />
          If there are any questions regarding this privacy policy you may contact
          us at hi@paws.lol
        </Typography>
       
      </Content>
    </Background>
  );
};

export default PrivacyPage;