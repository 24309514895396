import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { toast } from "react-simple-toasts";
import { firebase, functions } from "../firebaseConfig";
import {
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { CheckCircleOutline, CloseOutlined } from "@mui/icons-material";
import { COLORS } from "../utils/COLORS";
import { httpsCallable } from "firebase/functions";

const AdminPage = () => {
  const [data, setData] = useState({});
  const [avPets, setAvPets] = useState([]);
  const [hasAccess, setHasAccess] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkUserAuth = () => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user && user.email === "admin@admin.com") {
          // User is signed in and is the admin
          setUser(user);
          setHasAccess(true);
          // User is admin, fetch all database data
          const fetchAllData = async () => {
            try {
              const snapshot = await firebase.database().ref("/").once("value");
              const allData = snapshot.val();
              setData(allData);
              setAvPets(allData?.availablePets);
            } catch (error) {
              console.error("Error fetching data:", error);
              toast("Failed to fetch database data. Please try again.");
            }
          };
          fetchAllData();
        } else {
          // User is not admin or not signed in
          setUser(null);
          setHasAccess(false);
        }
      });
    };

    checkUserAuth();

    // Clean up the listener on unmount
    return () => {
      firebase.auth().onAuthStateChanged(() => {});
    };
  }, []);

  const handleSignIn = async () => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      const user = firebase.auth().currentUser;
      if (!user?.uid) {
        toast("No user found");
        return;
      }

      const userData = await firebase
        .database()
        .ref("users/" + user.uid)
        .once("value");

      const userDataObj = userData.val();

      setUser(userDataObj);
      toast("Successfully signed in!");
      setHasAccess(true);
    } catch (error) {
      console.error("Error signing in:", error);
      if (error.code === "auth/wrong-password") {
        toast("Incorrect password. Please try again.");
      } else {
        toast(
          error.message ||
            "An error occurred while signing in. Please try again."
        );
      }
      throw error; // Re-throw the error to be caught in handleSubmit
    }
  };

  //   const handleAcceptClaim = async (petId) => {
  //     try {
  //       const pet = avPets[petId];
  //       if (!pet) {
  //         toast("Pet not found");
  //         return;
  //       }

  //       // Process each pledge for the pet
  //       const pledges = pet.pledges || {};
  //       for (const [pledgeId, pledge] of Object.entries(pledges)) {
  //         if (pledge.status === "requires_capture") {
  //           // Process payment for the pledge
  //           const processPayment = httpsCallable(functions, "processPayment");
  //           const paymentResult = await processPayment({
  //             orderId: pledgeId,
  //             total: pledge.displayAmount, // Use displayAmount from the pledge
  //             customerId: pledge.customerId, // Use claimedStripe as customerId
  //             paymentIntentId: pledge.paymentIntentId,
  //             vendorStripeAccountId: pet.claimedStripe,
  //             uid: pet.claimedBy,
  //             dogSlug: pledge?.dogSlug,
  //           });

  //           console.log(`Payment result for pledge ${pledgeId}:`, paymentResult);
  //           // update the pledge status in the database
  //           await firebase
  //             .database()
  //             .ref(`availablePets/${petId}/pledges/${pledgeId}/status`)
  //             .set("processed");

  //           if (!paymentResult.data.success) {
  //             throw new Error(
  //               paymentResult.data.error ||
  //                 `Failed to process payment for pledge ${pledgeId}`
  //             );
  //           }
  //         }
  //       }

  //       // Update the pet's claim status in the database
  //       await firebase.database().ref(`availablePets/${petId}`).update({
  //         claimStatus: "accepted",
  //         euthanasiaStatus: "rescued",
  //       });

  //       // Update the user's profile to add the status of the animal
  //       await firebase
  //         .database()
  //         .ref(`users/${pet.claimedBy}/rescuedPets/${petId}`)
  //         .set({
  //           name: pet.name,
  //           breed: pet.breed,
  //           age: pet.age,
  //           rescueDate: firebase.database.ServerValue.TIMESTAMP,
  //           status: "rescued",
  //         });

  //       // Update local state
  //       setAvPets((prevPets) => ({
  //         ...prevPets,
  //         [petId]: {
  //           ...prevPets[petId],
  //           claimStatus: "accepted",
  //           euthanasiaStatus: "rescued",
  //         },
  //       }));

  //       toast("Claim accepted and pledges processed successfully");
  //     } catch (error) {
  //       console.error("Error accepting claim:", error);
  //       toast("Failed to accept claim. Please try again.");
  //     }
  //   };
  const handleAcceptClaim = async (petId) => {
    try {
      console.log("Starting handleAcceptClaim for petId:", petId);
      const pet = avPets[petId];
      if (!pet) {
        console.error("Pet not found for petId:", petId);
        toast("Pet not found");
        return;
      }

      console.log("Pet data:", pet);
      console.log("Pledges:", pet.pledges);

      // Process each pledge for the pet
      const pledges = pet.pledges || {};
      console.log("Number of pledges:", Object.keys(pledges).length);

      for (const [pledgeId, pledge] of Object.entries(pledges)) {
        console.log(`Processing pledge ${pledgeId}:`, pledge);

        if (pledge.status === "requires_capture") {
          console.log(`Attempting to process payment for pledge ${pledgeId}`);
          try {
            const processPayment = httpsCallable(functions, "processPayment");
            const paymentResult = await processPayment({
              orderId: pledgeId,
              total: pledge.displayAmount,
              customerId: pledge.customerId,
              paymentIntentId: pledge.paymentIntentId,
              vendorStripeAccountId: pet.claimedStripe,
              uid: pet.claimedBy,
              dogSlug: pledge?.dogSlug,
            });

            console.log(
              `Payment result for pledge ${pledgeId}:`,
              paymentResult
            );

            if (!paymentResult.data.success) {
              throw new Error(
                paymentResult.data.error ||
                  `Failed to process payment for pledge ${pledgeId}`
              );
            }

            // Update the pledge status in the database
            await firebase
              .database()
              .ref(`availablePets/${petId}/pledges/${pledgeId}/status`)
              .set("processed");

            console.log(
              `Successfully processed and updated pledge ${pledgeId}`
            );
          } catch (paymentError) {
            console.error(
              `Error processing payment for pledge ${pledgeId}:`,
              paymentError
            );
            // You might want to handle this error differently, e.g., continue processing other pledges
            // For now, we'll throw the error to stop the process
            throw paymentError;
          }
        } else {
          console.log(`Pledge ${pledgeId} does not require capture, skipping`);
        }
      }

      console.log("All pledges processed, updating pet status");

      // Update the pet's claim status in the database
      await firebase.database().ref(`availablePets/${petId}`).update({
        claimStatus: "accepted",
        euthanasiaStatus: "rescued",
      });

      // Update the user's profile to add the status of the animal
      await firebase
        .database()
        .ref(`users/${pet.claimedBy}/rescuedPets/${petId}`)
        .set({
          name: pet.name,
          breed: pet.breed,
          age: pet.age,
          rescueDate: firebase.database.ServerValue.TIMESTAMP,
          status: "rescued",
        });

      // Update local state
      setAvPets((prevPets) => ({
        ...prevPets,
        [petId]: {
          ...prevPets[petId],
          claimStatus: "accepted",
          euthanasiaStatus: "rescued",
        },
      }));

      console.log("Claim accepted and pledges processed successfully");
      toast("Claim accepted and pledges processed successfully");
    } catch (error) {
      console.error("Error accepting claim:", error);
      toast("Failed to accept claim. Please try again.");
    }
  };

  const handleDenyClaim = (petId) => {
    // TODO: Implement deny claim logic
    console.log("Denied claim for pet:", petId);
    toast("Claim denied");
  };

  const calculateTotalPledged = (pledges) => {
    if (!pledges) return 0;
    return Object.values(pledges).reduce((total, pledge) => {
      if (pledge.status === "requires_capture") {
        return total + (pledge.displayAmount || 0);
      }
      return total;
    }, 0);
  };

  if (!hasAccess) {
    return (
      <Container>
        <Title>Admin Page</Title>
        <InputContainer>
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            placeholder="Pass"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button onClick={handleSignIn}>LOGIN</Button>
        </InputContainer>
      </Container>
    );
  }

  return (
    <Box sx={{ padding: "5%" }}>
      <Typography variant="h4" gutterBottom>
        Admin Page
      </Typography>
      <Typography variant="h5" gutterBottom>
        Pending Claims
      </Typography>
      <List>
        {avPets && Object.values(avPets).length > 0 ? (
          Object.values(avPets)
            .filter((pet) => pet.claimStatus === "pending")
            .map((pet) => (
              <ListItem
                key={pet.id}
                sx={{ border: "1px solid #ccc", marginBottom: 2, padding: 2 }}
              >
                <ListItemText
                  primary={pet.name}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        display="block"
                      >
                        Breed: {pet.breed}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        display="block"
                      >
                        Age: {pet.age}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        display="block"
                      >
                        Claimed By: {pet.claimedBy}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        display="block"
                        sx={{ fontWeight: "bold", color: COLORS.primaryGreen }}
                      >
                        Total Pledged: $
                        {calculateTotalPledged(pet.pledges).toFixed(2)}
                      </Typography>
                      <img
                        src={pet.proofOfRescueUrl}
                        alt="Proof of Rescue"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "400px",
                          marginTop: "10px",
                        }}
                      />
                    </>
                  }
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    gap: 2,
                    marginTop: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you want to accept this claim?"
                        )
                      ) {
                        handleAcceptClaim(pet.id);
                      }
                    }}
                    startIcon={<CheckCircleOutline />}
                    sx={{ minWidth: 120, backgroundColor: COLORS.primaryGreen }}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => handleDenyClaim(pet.id)}
                    startIcon={<CloseOutlined />}
                    sx={{ minWidth: 120, backgroundColor: COLORS.secondary }}
                  >
                    Deny
                  </Button>
                </Box>
              </ListItem>
            ))
        ) : (
          <ListItem>
            <ListItemText primary="No pending claims" />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const DataContainer = styled.div`
  margin-top: 20px;
`;

const DataItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
`;

export default AdminPage;
