import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Typography,
  AppBar,
  Button,
  Avatar,
  Paper,
  Toolbar,
  Divider,
  Modal,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  // Add these imports
} from "@mui/material";
import { keyframes, styled } from "@mui/system";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ChatBubbleOutline, WarningAmber } from "@mui/icons-material";
import { COLORS } from "../utils/COLORS";
import LogoImgShortDark from "../assets/trans_logo_short.png";
import { useParams } from "react-router-dom";
import { ImageList, ImageListItem } from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import GlobalStateContext from "../context/globalStateContext";
import PledgeModal from "../components/PledgeModal";
import UserContext from "../context/userContext";
import AuthModal from "../components/AuthModal";
import CustomToolbar from "../components/CustomToolbar";
import ClaimFundsModal from "../components/ClaimFundsModal";
import AddCardModal from "../components/AddCardModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { storage } from "../firebaseConfig";
import { off, onValue, ref } from "firebase/database";
import { database } from "../firebaseConfig";
import RescueDetailsModal from "../components/RescueDetailsModal";
import SendIcon from "@mui/icons-material/Send";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { CircularProgress } from "@mui/material";
import NiceAvatar, { genConfig } from "react-nice-avatar";
import { motion } from "framer-motion";
import { Snackbar } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const stripePromise = loadStripe(
  "pk_test_51Q5TzfIWBW0yKcNbXRKebR5EkZEk19VJZqyWSoabnT760WQgR7GGadARjL9gZpAzBvkCvmHK2xlDxHXMYVPItEPN00Mh95kJcQ"
);

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
}));

const ShareModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
  width: "90%",
  maxWidth: "400px",
  textAlign: "center",
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(1),
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
}));

const ShareButton = styled(Button)(({ theme }) => ({
  backgroundColor: COLORS.blue,
  color: "white",
  fontWeight: "bold",
  borderRadius: "20px",
  padding: "8px 16px",
  marginBottom: "16px",
  "&:hover": {
    backgroundColor: COLORS.darkBlue,
  },
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
  width: "90%",
  maxWidth: "400px",
  maxHeight: "80vh",
  overflowY: "auto",
  textAlign: "center",
}));

const UserInfoContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "16px",
});

const AvatarContainer = styled(Box)({
  marginRight: "12px",
});

const PageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  justifyContent: "flex-start",
  alignItems: "center",
  backgroundColor: "#ffffff",
  boxSizing: "border-box", // Add this line
}));

const Card = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderRadius: "16px",
  overflow: "hidden",
  marginTop: theme.spacing(2),
  marginBottom: 100,
  boxSizing: "border-box",
  // Remove any border
  border: "none",
  // Explicitly set box-shadow to none
  boxShadow: "none",
  // Ensure background is solid
  backgroundColor: "#ffffff",
  // Override any elevation
  elevation: 0,
}));

const ScrollableCardContent = styled(Box)(({ theme }) => ({
  // maxHeight: "calc(100vh - 200px)", // Adjust this value as needed
  overflowY: "auto",
  overflowX: "hidden",
  height: "100%",
  width: "90%",
  padding: theme.spacing(3, 3, 3, 3),
  "&::-webkit-scrollbar": {
    width: "100%",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    borderRadius: "10px",
  },
}));

const Header = styled(AppBar)({
  backgroundColor: COLORS.yellow,
  boxShadow: "none",
});

const TabContainer = styled(Box)({
  display: "flex",
  marginBottom: "24px",
  backgroundColor: "#f5f5f5",
  borderRadius: "20px",
  padding: "4px",
  justifyContent: "space-between",
});

const Tab = styled(Button)(({ theme, variant }) => ({
  borderRadius: "16px",
  textTransform: "none",
  padding: "8px 16px",
  fontSize: "14px",
  fontWeight: "medium",
  flex: 1,
  color:
    variant === "contained"
      ? theme.palette.primary.contrastText
      : theme.palette.text.secondary,
  backgroundColor:
    variant === "contained" ? theme.palette.primary.main : "transparent",
  "&:hover": {
    backgroundColor:
      variant === "contained" ? theme.palette.primary.dark : "#e0e0e0",
  },
  "&:not(:last-child)": {
    marginRight: "4px",
  },
}));

const TopUserContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "24px",
});

const UserInfo = styled(Box)({
  // marginLeft: "16px",
  display: "flex",
  width: "100%",
  flexDirection: "column",
});

const ActivityInputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(3),
  gap: theme.spacing(2),
}));

const ActivityTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: "20px",
    backgroundColor: "#f5f5f5",
    "&.Mui-focused fieldset": {
      borderColor: COLORS.yellow,
    },
  },
}));

const ActionRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: "20px",
  padding: "10px 20px",
  backgroundColor: COLORS.yellow,
  color: "black",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: COLORS.darkYellow,
  },
}));

const ImagePreview = styled("img")({
  width: "100%",
  maxHeight: "200px",
  objectFit: "cover",
  borderRadius: "8px",
  marginTop: "8px",
});

const TotalUsersContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  marginBottom: "16px",
});
const UserAvatars = styled(Box)({
  display: "flex",
  marginBottom: "16px",
});
const ViewAllButton = styled(Button)({
  textTransform: "none",
});
const PercentageBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  color: "#4caf50",
  fontSize: "14px",
  fontWeight: "bold",
});

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  marginBottom: 0,
  lineHeight: 1.6,
  overflow: "hidden",
  display: "-webkit-box",
  "-webkit-line-clamp": 3,
  "-webkit-box-orient": "vertical",
}));

const ReadMoreButton = styled(Button)(({ theme }) => ({
  padding: 0,
  marginTop: 0,
  marginBottom: "24px",
  textTransform: "none",
  fontWeight: "bold",
  color: COLORS.lightGray,
  alignSelf: "flex-end",
}));

const ActivityPost = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  padding: theme.spacing(2),
  backgroundColor: "white",
  border: `1px solid ${COLORS.lightGray2}`,
  borderRadius: "8px",
}));

const PostDate = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const PostDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const SharesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(1),
}));

const SharesTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const InteractionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: theme.spacing(1),
  width: "100%",
}));

const InteractionItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const InteractionTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.3; }
  100% { opacity: 1; }
`;

const DogProfilePage = () => {
  const { slug } = useParams();
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [dogProfile, setDogProfile] = useState(null);
  const [activeTab, setActiveTab] = useState("profile");
  const [countdown, setCountdown] = useState("");
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [pledgeData, setPledgeData] = useState(null);
  const [totalPledged, setTotalPledged] = useState(0);
  const [topPledger, setTopPledger] = useState(null);
  const [pledgerCount, setPledgerCount] = useState(0);
  const [newActivity, setNewActivity] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [username, setUsername] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showFosterModal, setShowFosterModal] = useState(false);
  const [fosterFormData, setFosterFormData] = useState({
    fullName: "",
    address: "",
    residenceType: "",
    phoneNumber: "",
  });
  const [submittingFoster, setSubmittingFoster] = useState(false);
  const [fosterSnackbarOpen, setFosterSnackbarOpen] = useState(false);
  const fileInputRef = useRef(null);
  const avatarConfig = useMemo(() => genConfig(), []);

  const postAvatarConfigs = useMemo(() => {
    const configs = {};
    Object.values(dogProfile?.posts || {}).forEach((post) => {
      configs[post.id] = genConfig();
    });
    return configs;
  }, [dogProfile?.posts]);

  useEffect(() => {
    const dogRef = ref(database, `availablePets/${slug}`);

    const unsubscribe = onValue(
      dogRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setDogProfile(data);
        } else {
          setDogProfile(null);
        }
      },
      (error) => {
        console.error("Error fetching dog profile:", error);
        setDogProfile(null);
      }
    );

    // Cleanup function
    return () => {
      off(dogRef);
    };
  }, [slug]);

  useEffect(() => {
    if (!dogProfile) return;

    const pledgesRef = ref(
      database,
      `availablePets/${dogProfile.slug}/pledges`
    );

    const unsubscribe = onValue(pledgesRef, (snapshot) => {
      const pledgesData = snapshot.val();

      if (pledgesData) {
        // Calculate total pledged amount
        const total = Object.values(pledgesData).reduce(
          (sum, pledge) => sum + pledge.displayAmount,
          0
        );
        setTotalPledged(total);

        // Find top pledger
        const top = Object.values(pledgesData).reduce(
          (top, pledge) => (pledge.amount > top.amount ? pledge : top),
          { amount: 0 }
        );
        setTopPledger(top);

        // Count number of pledgers
        setPledgerCount(Object.keys(pledgesData).length);
      } else {
        setTotalPledged(0);
        setTopPledger(null);
        setPledgerCount(0);
      }
    });

    return () => off(pledgesRef);
  }, [dogProfile]);

  const calculateCountdown = (dogProfile) => {
    const now = new Date();
    const euthanasiaDate = new Date(dogProfile?.euthanasiaDate);
    const difference = euthanasiaDate.getTime() - now.getTime();

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      let countdownString = "";
      if (days > 0) {
        countdownString += `${days}d `;
      }
      if (hours > 0 || days > 0) {
        countdownString += `${hours}h `;
      }
      if (minutes > 0 || hours > 0 || days > 0) {
        countdownString += `${minutes}m `;
      }
      countdownString += `${seconds}s`;

      return countdownString.trim();
    } else {
      return "Time expired";
    }
  };

  useEffect(() => {
    if (!dogProfile) return;
    const timer = setInterval(() => {
      setCountdown(calculateCountdown(dogProfile));
    }, 1000);

    return () => clearInterval(timer);
  }, [dogProfile]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleSubmitActivity = async () => {
    if (!newActivity.trim()) {
      alert("Please enter some text for your post.");
      return;
    }

    if (user && !user.username) {
      setShowUsernameModal(true);
      return;
    }

    setUploading(true);

    try {
      let imageUrl = null;
      if (selectedImage) {
        const storageRef = storage.ref(
          `post_images/${dogProfile.id}/${Date.now()}`
        );
        const uploadTask = await storageRef.put(selectedImage);
        imageUrl = await uploadTask.ref.getDownloadURL();
      }

      const newPost = {
        date: new Date().toISOString(),
        description: newActivity,
        id: Date.now().toString(),
        images: imageUrl ? [imageUrl] : [],
        username: user.username,
      };

      await database.ref(`availablePets/${dogProfile.id}/posts`).push(newPost);

      // Reset the input fields after submission
      setNewActivity("");
      setSelectedImage(null);
    } catch (error) {
      console.error("Error submitting post:", error);
      alert("An error occurred while submitting your post. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const handleFosterClick = () => {
    if (!user) {
      // no user
      setGlobalState({
        ...globalState,
        showAuthModal: true,
      });
    } else {
      setShowFosterModal(true);
    }
  };

  const handleSetUsername = async () => {
    if (username.trim()) {
      try {
        await database.ref(`users/${user.uid}`).update({ username });
        setUser({ ...user, username });
        setShowUsernameModal(false);
      } catch (error) {
        console.error("Error setting username:", error);
        alert(
          "An error occurred while setting your username. Please try again."
        );
      }
    }
  };

  const handleShare = () => {
    setShowShareModal(true);
  };

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };

  const shareUrl = `https://www.paws.lol/${dogProfile?.slug}`;

  const handleSocialShare = (platform) => {
    let shareLink;
    switch (platform) {
      case "facebook":
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "twitter":
        shareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          shareUrl
        )}&text=${encodeURIComponent(`Help save ${dogProfile?.name}!`)}`;
        break;
      case "linkedin":
        shareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          shareUrl
        )}&title=${encodeURIComponent(`Help save ${dogProfile?.name}!`)}`;
        break;
      case "whatsapp":
        shareLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `Help save ${dogProfile?.name}! ${shareUrl}`
        )}`;
        break;
      case "email":
        shareLink = `mailto:?subject=${encodeURIComponent(
          `Help save ${dogProfile?.name}!`
        )}&body=${encodeURIComponent(
          `Check out this dog that needs help: ${shareUrl}`
        )}`;
        break;
      default:
        return;
    }
    window.open(shareLink, "_blank");
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setSnackbarOpen(true);
    });
  };

  const residenceTypes = [
    "House with yard",
    "House without yard",
    "Apartment",
    "Condo",
    "Townhouse",
    "Mobile home",
    "Farm/Rural property",
    "Other",
  ];

  const handleFosterSubmit = async () => {
    if (!user) {
      alert("Please log in to submit a foster application.");
      return;
    }

    setSubmittingFoster(true);

    try {
      // Save foster application data to user's profile
      await database
        .ref(`users/${user.uid}/fosterApplications/${dogProfile.id}`)
        .set({
          ...fosterFormData,
          dogId: dogProfile.id,
          dogSlug: dogProfile.slug,
          dogName: dogProfile.name,
          appliedAt: new Date().toISOString(),
        });

      // Update the dog's profile to indicate a foster application
      await database
        .ref(`availablePets/${dogProfile.id}/fosterApplications/${user.uid}`)
        .set({
          userId: user.uid,
          ...fosterFormData,
          appliedAt: new Date().toISOString(),
        });

      // await database.ref(`availablePets/${dogProfile.id}/posts`).push(newPost);

      // Close the modal and show success message
      setShowFosterModal(false);
      setFosterSnackbarOpen(true);

      // Reset form data
      setFosterFormData({
        fullName: "",
        address: "",
        residenceType: "",
        phoneNumber: "",
      });
    } catch (error) {
      console.error("Error submitting foster application:", error);
      alert(
        "An error occurred while submitting your application. Please try again."
      );
    } finally {
      setSubmittingFoster(false);
    }
  };

  const renderFosterModal = () => {
    return (
      <StyledModal
        open={showFosterModal}
        onClose={() => setShowFosterModal(false)}
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
      >
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <ModalContent>
            <Typography variant="h5" gutterBottom align="center">
              Foster {dogProfile?.name}
            </Typography>
            <Typography variant="body1" paragraph align="center">
              We need more people like you
            </Typography>
            <TextField
              fullWidth
              label="Full Name"
              variant="outlined"
              margin="normal"
              value={fosterFormData.fullName}
              onChange={(e) =>
                setFosterFormData({
                  ...fosterFormData,
                  fullName: e.target.value,
                })
              }
            />
            <TextField
              fullWidth
              label="Address"
              variant="outlined"
              margin="normal"
              value={fosterFormData.address}
              onChange={(e) =>
                setFosterFormData({
                  ...fosterFormData,
                  address: e.target.value,
                })
              }
            />
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Residence Type</InputLabel>
              <Select
                value={fosterFormData.residenceType}
                onChange={(e) =>
                  setFosterFormData({
                    ...fosterFormData,
                    residenceType: e.target.value,
                  })
                }
                label="Residence Type"
              >
                {residenceTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Phone Number"
              variant="outlined"
              margin="normal"
              value={fosterFormData.phoneNumber}
              onChange={(e) =>
                setFosterFormData({
                  ...fosterFormData,
                  phoneNumber: e.target.value,
                })
              }
            />
            <Button
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
                backgroundColor: COLORS.primaryGreen,
                "&:hover": {
                  backgroundColor: COLORS.darkGreen,
                },
              }}
              onClick={handleFosterSubmit}
              disabled={submittingFoster}
            >
              {submittingFoster ? "Submitting..." : "Submit Foster Application"}
            </Button>
          </ModalContent>
        </motion.div>
      </StyledModal>
    );
  };

  const renderShareModal = () => {
    return (
      <StyledModal
        open={showShareModal}
        onClose={handleCloseShareModal}
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
      >
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <ShareModalContent>
            <Typography variant="h5" sx={{ mb: 3 }}>
              Share {dogProfile?.name}'s' Profile
            </Typography>
            <Box>
              <SocialIconButton
                onClick={() => handleSocialShare("facebook")}
                aria-label="share on Facebook"
              >
                <FacebookIcon sx={{ color: "#3b5998" }} />
              </SocialIconButton>
              <SocialIconButton
                onClick={() => handleSocialShare("twitter")}
                aria-label="share on Twitter"
              >
                <TwitterIcon sx={{ color: "#1da1f2" }} />
              </SocialIconButton>
              <SocialIconButton
                onClick={() => handleSocialShare("linkedin")}
                aria-label="share on LinkedIn"
              >
                <LinkedInIcon sx={{ color: "#0077b5" }} />
              </SocialIconButton>
              {/* <SocialIconButton onClick={() => handleSocialShare('whatsapp')} aria-label="share on WhatsApp">
                <WhatsApp sx={{ color: '#25d366' }} />
              </SocialIconButton> */}
              <SocialIconButton
                onClick={() => handleSocialShare("email")}
                aria-label="share via Email"
              >
                <EmailIcon sx={{ color: "#d44638" }} />
              </SocialIconButton>
              <SocialIconButton onClick={handleCopyLink} aria-label="copy link">
                <ContentCopyIcon />
              </SocialIconButton>
            </Box>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Your share could be the key to {dogProfile?.name}'s forever home.
            </Typography>
          </ShareModalContent>
        </motion.div>
      </StyledModal>
    );
  };

  const renderEnterUserNameModal = () => {
    return (
      <StyledModal
        open={showUsernameModal}
        onClose={() => setShowUsernameModal(false)}
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
      >
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <ModalContent>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: COLORS.primaryGreen, mb: 3 }}
            >
              Set Your Username
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{ mb: 3 }}
            />
            <Button
              variant="contained"
              fullWidth
              onClick={handleSetUsername}
              sx={{
                backgroundColor: COLORS.primaryGreen,
                "&:hover": {
                  backgroundColor: COLORS.darkGreen,
                },
              }}
            >
              Set Username
            </Button>
          </ModalContent>
        </motion.div>
      </StyledModal>
    );
  };

  return (
    <PageContainer>
      <Header position="static">
        <CustomToolbar />
      </Header>
      <Card>
        <ScrollableCardContent>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              {dogProfile?.images[0] && (
                <Avatar
                  src={dogProfile?.images[0]}
                  sx={{ width: 64, height: 64, marginRight: "16px" }}
                />
              )}
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    marginBottom: "8px",
                  }}
                >
                  <Typography variant="5" fontWeight="bold">
                    {dogProfile?.name}
                    <Typography variant="caption">
                      ({dogProfile?.gender})
                    </Typography>
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <WarningAmber
                      sx={{
                        fontSize: "16px",
                        marginRight: "8px",
                        color: COLORS.secondary,
                        animation:
                          calculateCountdown(dogProfile) !== "Time expired"
                            ? `${blinkAnimation} 1.5s ease-in-out infinite`
                            : "none",
                      }}
                    />
                    <Typography
                      variant="caption"
                      sx={{ color: "black", textAlign: "right" }}
                    >
                      {countdown}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {dogProfile?.breed}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    •
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {dogProfile?.age}yo
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    •
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {dogProfile?.weight} lbs
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <TabContainer>
            <Tab
              key={`activity-${activeTab}`}
              onClick={() => setActiveTab("activity")}
              sx={{
                backgroundColor:
                  activeTab === "activity" ? COLORS.yellow : "transparent",
                color: activeTab === "activity" ? "black" : "inherit",
                fontWeight: activeTab === "activity" ? "bold" : "normal",
              }}
            >
              Activity
            </Tab>

            <Tab
              key={`profile-${activeTab}`}
              onClick={() => setActiveTab("profile")}
              sx={{
                backgroundColor:
                  activeTab === "profile" ? COLORS.yellow : "transparent",
                color: activeTab === "profile" ? "black" : "inherit",
                fontWeight: activeTab === "profile" ? "bold" : "normal",
              }}
            >
              Profile
            </Tab>
            {/* <Tab
                  onClick={() => setActiveTab("gifts")}
                  sx={{
                    backgroundColor:
                      activeTab === "gifts" ? COLORS.yellow : "transparent",
                    color: activeTab === "gifts" ? "black" : "inherit",
                    fontWeight: activeTab === "gifts" ? "bold" : "normal",
                  }}
                >
                  Gifts
                </Tab> */}
          </TabContainer>

          {/* SHARE POST  */}
          <ShareButton
            fullWidth
            variant="contained"
            startIcon={<ShareOutlinedIcon />}
            onClick={handleShare}
          >
            SHARE {dogProfile?.name}
          </ShareButton>
          {renderShareModal()}

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            message="Link copied to clipboard!"
          />

          {activeTab === "profile" && (
            <>
              {dogProfile && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  {Math.floor(
                    (new Date() - new Date(dogProfile.shelterEntryDate)) /
                      (1000 * 60 * 60 * 24)
                  )}{" "}
                  DAYS IN SHELTER
                </Typography>
              )}
              <DescriptionTypography
                variant="body1"
                color="text.primary"
                sx={{
                  WebkitLineClamp: showFullDescription ? "unset" : 3,
                }}
              >
                {dogProfile?.description}
              </DescriptionTypography>
              <ReadMoreButton
                onClick={() => setShowFullDescription(!showFullDescription)}
                color="primary"
              >
                {showFullDescription ? "Read Less" : "Read More"}
              </ReadMoreButton>

              <TotalUsersContainer>
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    KILL-RISK STATUS
                  </Typography>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{
                      color:
                        dogProfile?.euthanasiaStatus === "pending"
                          ? dogProfile?.claimStatus === "pending"
                            ? COLORS.orange
                            : COLORS.secondary
                          : dogProfile?.euthanasiaStatus === "rescued"
                          ? COLORS.primaryGreen
                          : COLORS.secondary,
                      animation:
                        dogProfile?.euthanasiaStatus === "pending" || // only animate on pending
                        (dogProfile?.claimStatus === "pending" &&
                          calculateCountdown(dogProfile) !== "Time expired") //  dogProfile?.euthanasiaStatus === "pending"
                          ? `${blinkAnimation} 1.5s ease-in-out infinite`
                          : "none",
                    }}
                  >
                    {/* {dogProfile?.euthanasiaStatus === "pending" */}
                    {calculateCountdown(dogProfile) !== "Time expired"
                      ? dogProfile?.claimStatus === "pending"
                        ? "Claim in progress"
                        : dogProfile?.claimStatus === "accepted"
                        ? "Rescued!"
                        : "Needs rescue"
                      : dogProfile?.euthanasiaStatus === "rescued"
                      ? "Rescued"
                      : "Expired :("}
                  </Typography>
                  {dogProfile?.euthanasiaStatus === "pending" ||
                  dogProfile?.claimStatus === "pending" ? (
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{
                        color:
                          dogProfile?.euthanasiaStatus === "pending"
                            ? COLORS.secondary
                            : dogProfile?.euthanasiaStatus === "rescued"
                            ? COLORS.primaryGreen
                            : COLORS.secondary,
                        animation:
                          calculateCountdown(dogProfile) !== "Time expired"
                            ? `${blinkAnimation} 1.5s ease-in-out infinite`
                            : "none",
                      }}
                    >
                      Kill Date:{" "}
                      {dogProfile?.euthanasiaDate &&
                        new Date(
                          dogProfile?.euthanasiaDate
                        ).toLocaleDateString()}
                    </Typography>
                  ) : (
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{
                        color:
                          dogProfile?.euthanasiaStatus === "pending"
                            ? COLORS.secondary
                            : dogProfile?.euthanasiaStatus === "rescued"
                            ? COLORS.primaryGreen
                            : COLORS.secondary,
                      }}
                    >
                      Rescued Date:{" "}
                      {dogProfile?.claimedAt &&
                        new Date(dogProfile?.claimedAt).toLocaleDateString()}
                    </Typography>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    disabled={
                      dogProfile?.claimStatus === "pending" ||
                      dogProfile?.claimStatus === "accepted"
                    }
                    onClick={() => {
                      if (!user) {
                        // no user
                        setGlobalState({
                          ...globalState,
                          showAuthModal: true,
                        });
                      } else if (
                        !user?.rescueOrganization || // rescue
                        !user?.stripe_account_id // stripe connect vendor
                      ) {
                        // no rescue details
                        setGlobalState({
                          ...globalState,
                          showRescueDetailsModal: true,
                        });
                      } else {
                        // claim dog + funds
                        setGlobalState({
                          ...globalState,
                          showClaimFundsModal: true,
                          selectedDog: dogProfile,
                        });
                      }
                    }}
                    variant="text"
                    sx={{
                      fontSize: "12px",
                      color: "black",
                      fontWeight: "bold",
                      alignItems: "center",
                      border:
                        dogProfile?.claimStatus === "pending" ||
                        dogProfile?.claimStatus === "accepted"
                          ? `1px solid #ccc`
                          : `1px solid black`,
                    }}
                  >
                    {dogProfile?.claimStatus === "pending"
                      ? "CLAIM PENDING"
                      : dogProfile?.claimStatus === "accepted"
                      ? "CLAIMED"
                      : "CLAIM FUNDS"}
                  </Button>
                  <Typography variant="caption" color="text.secondary">
                    For Rescues
                  </Typography>
                </Box>
              </TotalUsersContainer>
              <TotalUsersContainer>
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    FOSTER STATUS
                  </Typography>

                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{
                      color: dogProfile?.activeFoster
                        ? COLORS.primaryGreen
                        : COLORS.blue,
                    }}
                  >
                    {/* {dogProfile?.euthanasiaStatus === "pending" */}
                    {dogProfile?.activeFoster ? "With Foster" : "Needs Foster"}
                  </Typography>

                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{
                      color: dogProfile?.activeFoster
                        ? COLORS.primaryGreen
                        : COLORS.blue,
                    }}
                  >
                    {dogProfile?.fosterApplications &&
                      Object.values(dogProfile?.fosterApplications)?.length >
                        0 &&
                      `Pending Fosters: ${
                        Object.values(dogProfile?.fosterApplications)?.length
                      }`}
                  </Typography>
                </Box>

                <Button
                  onClick={handleFosterClick}
                  variant="text"
                  sx={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                    maxWidth: "185px",
                    alignItems: "center",
                    border: `1px solid black`,
                  }}
                >
                  FOSTER {dogProfile?.name}
                </Button>
              </TotalUsersContainer>

              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginTop: "24px", textAlign: "center" }}
              >
                Rescue orgs - please save {dogProfile?.name} and{" "}
                <strong>
                  claim pledges for same-day Stripe transfer (100%)
                </strong>
                .
              </Typography>
              {/* <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginTop: "14px", textAlign: "center" }}
              >
                <strong>Proof of Rescue</strong> required to claim pledges.
              </Typography> */}

              <Divider sx={{ marginTop: "24px" }} />

              <TopUserContainer
                sx={{ marginTop: "24px", justifyContent: "center" }}
              >
                <UserInfo>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: "16px",
                      width: "100%",
                    }}
                  >
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        TOTAL PLEDGED
                      </Typography>
                      <Typography variant="subtitle1" fontWeight="bold">
                        ${totalPledged.toFixed(2)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        TOP PLEDGER
                      </Typography>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {topPledger
                          ? topPledger.userEmail
                            ? topPledger.userEmail.slice(0, 5) + "..."
                            : "Anon"
                          : "N/A"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        PLEDGERS
                      </Typography>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {pledgerCount}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        TOP CORP PLEDGER
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{ color: COLORS.lightGray4 }}
                      >
                        Coming soon
                      </Typography>
                    </Box>
                  </Box>
                </UserInfo>
              </TopUserContainer>
            </>
          )}

          {activeTab === "activity" && (
            <>
              <ActivityInputContainer>
                <ActivityTextField
                  variant="outlined"
                  placeholder="Add a new activity..."
                  value={newActivity}
                  onChange={(e) => setNewActivity(e.target.value)}
                  multiline
                  rows={3}
                />
                <ActionRow>
                  <IconButton
                    onClick={() => fileInputRef.current.click()}
                    sx={{ color: COLORS.lightGray4 }}
                  >
                    <AddPhotoAlternateIcon />
                  </IconButton>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageUpload}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <SubmitButton
                    variant="contained"
                    endIcon={
                      uploading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <SendIcon />
                      )
                    }
                    onClick={handleSubmitActivity}
                    disabled={!newActivity.trim() || uploading}
                  >
                    {uploading ? "Posting..." : "Post"}
                  </SubmitButton>
                </ActionRow>
                {selectedImage && (
                  <ImagePreview
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected"
                  />
                )}
              </ActivityInputContainer>
              {Object.values(dogProfile?.posts || {}).map((post) => (
                <>
                  <ActivityPost key={post.id}>
                    <UserInfoContainer
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <AvatarContainer>
                          <NiceAvatar
                            style={{ width: "40px", height: "40px" }}
                            {...postAvatarConfigs[post.id]} // Use the memoized config for this post
                          />
                        </AvatarContainer>
                        <Typography variant="subtitle1">
                          {post.username || "Anonymous"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <PostDate variant="body2">
                          {new Date(post.date).toLocaleDateString()}
                        </PostDate>
                        <PostDate variant="body2">
                          {new Date(post.date).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </PostDate>
                      </Box>
                    </UserInfoContainer>

                    {post.images &&
                      post.images.length > 0 &&
                      (post.images.length === 1 ? (
                        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                          <img
                            src={post.images[0]}
                            alt="Post image"
                            loading="lazy"
                            style={{
                              width: "100%",
                              height: "auto",
                              maxHeight: "300px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />
                        </Box>
                      ) : (
                        <ImageList cols={3} rowHeight={120} gap={8}>
                          {post.images.map((img, index) => (
                            <ImageListItem key={index}>
                              <img
                                src={img}
                                alt={`Post image ${index + 1}`}
                                loading="lazy"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "4px",
                                }}
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      ))}

                    <PostDescription variant="body1">
                      {post.description}
                    </PostDescription>

                    <InteractionContainer>
                      <InteractionItem>
                        {/* <ChatBubbleOutline fontSize="small" color="action" />
                        <InteractionTypography variant="body2">
                          comments
                        </InteractionTypography> */}
                      </InteractionItem>
                      <InteractionItem>
                        <ShareOutlinedIcon fontSize="small" color="action" />
                        <InteractionTypography variant="body2">
                          {post.shares || 0} shares
                        </InteractionTypography>
                      </InteractionItem>
                    </InteractionContainer>
                  </ActivityPost>
                </>
              ))}
              {Object.keys(dogProfile?.posts || {}).length === 0 && (
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="text.secondary"
                >
                  No activity posts available.
                </Typography>
              )}
            </>
          )}
        </ScrollableCardContent>
      </Card>

      {/* ENTER YOUR USER NAME MODAL  */}
      {renderEnterUserNameModal()}

      <Button
        onClick={() => {
          if (!user) {
            // no user
            setGlobalState({
              ...globalState,
              showAuthModal: true,
            });
          } else {
            if (!user?.stripeCustomerId) {
              setGlobalState({
                ...globalState,
                showAddCardModal: true,
              });
            } else {
              // user
              setGlobalState({
                ...globalState,
                showPledgeModal: true,
                selectedDog: dogProfile,
              });
            }
          }
        }}
        variant="contained"
        size="large"
        sx={{
          position: "fixed",
          bottom: "24px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "90%",
          maxWidth: "600px",
          backgroundColor: COLORS.primaryGreen,
          color: "#ffffff",
          fontWeight: "bold",
          fontSize: "18px",
          padding: "16px",
          borderRadius: "12px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
          transition: "all 0.3s ease",
          zIndex: 1000,
          "&:hover": {
            backgroundColor: COLORS.darkGreen,
            transform: "translateX(-50%) translateY(-2px)",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
          },
          "&:active": {
            transform: "translateX(-50%) translateY(1px)",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          },
          "&:focus": {
            backgroundColor: COLORS.primaryGreen,
          },
        }}
      >
        PLEDGE TO SAVE {dogProfile?.name?.toUpperCase()}
      </Button>
      <Elements stripe={stripePromise}>
        {globalState.showPledgeModal && <PledgeModal />}
        {globalState.showAddCardModal && <AddCardModal />}
      </Elements>
      {globalState.showAuthModal && <AuthModal />}
      {globalState.showClaimFundsModal && <ClaimFundsModal />}
      {globalState?.showRescueDetailsModal && <RescueDetailsModal />}
      {renderFosterModal()}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={fosterSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setFosterSnackbarOpen(false)}
        message={`Foster application for ${dogProfile?.name} submitted successfully!`}
      />
    </PageContainer>
  );
};

export default DogProfilePage;
